import {asFormData, asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    fetchScopes: (data) => asJson(axios).get(getVerUri('scopes'), data),
    fetchScope: (scopeId) => asJson(axios).get(getVerUri('scopes/' + scopeId)),
    createScope: (data) => asFormData(axios).post(getVerUri('scopes'), data),
    updateScope: (scopeId, data) => asFormData(axios).post(getVerUri(`scopes/${scopeId}?_method=PUT`), data),
    deleteScope: (scopeId) => asJson(axios).delete(getVerUri('scopes/' + scopeId)),
    fetchAttachments: (scopeId) => asJson(axios).get(getVerUri('scope/attachment/list?scope_id=' + scopeId)),
    addAttachments: (data) => asFormData(axios).post(getVerUri('scope/attachment/add'), data),
    checkAttachments: (data) => asJson(axios).post(getVerUri('scope/attachment/check'), data),
    deleteAttachment: (data) => asJson(axios).post(getVerUri('scope/attachment/delete'), data),
    fetchEditors: (scopeId) => asJson(axios).get(getVerUri('scope/get-editors?scope_id=' + scopeId)),
    scopeApprovalsCreate: (scopeId) => asJson(axios).post(getVerUri(`scope/approvals/${scopeId}`)),
    scopeApprovalsList: (scopeId) => asJson(axios).get(getVerUri(`scope/approvals?scope_id=${scopeId}`)),
    scopeApprovalsReject: (scopeId) => asJson(axios).post(getVerUri(`scope/approvals/reject?scope_id=${scopeId}`)),
    scopeApprovalsApprove: (scopeId) => asJson(axios).post(getVerUri(`scope/approvals/approve?scope_id=${scopeId}`)),
    scopeCheckPermission: (projectId) => asJson(axios).get(getVerUri(`scope/check-permission?project_id=${projectId}`)),
    archivateTasks: data => asJson(axios).post(getVerUri(`scope/scheduled-tasks/archivate`), data),
    storeCustomFields: data => asJson(axios).post(getVerUri('entity/custom-fields'), data),
    deleteCustomField: fieldId => asJson(axios).delete(getVerUri('entity/custom-fields/' + fieldId)),
    updateCustomField: (data, fieldId) => asJson(axios).put(getVerUri('entity/custom-fields/' + fieldId), data)
})
