export default {
    inject: ['toast', 'downloadToast'],
    methods: {
        handleDownloadPromise(promise, downloadId, downloadDescription) {
            this.downloadToast.open(downloadId, downloadDescription);

            promise
                .then(response => {
                    this.downloadToast.update(downloadId);
                    this.downloadFileFromResponse(response, downloadDescription);
                })
                .catch(() => {
                    this.downloadToast.clear();
                    this.toast('danger', 'File download failed!');
                });
        },
        downloadFileFromResponse(response, fileName) {
            let link = document.createElement('a');

            link.href = URL.createObjectURL(response.data);
            link.download = fileName;
            link.target = '_blank';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    }
}
